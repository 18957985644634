// Components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";

// Themes
import theme from "@/theme";

// Types
import IEndCallButtonProps from "@/interfaces/global-components/video/sub-components/IEndCallButtonProps";
import Stopwatch from "../../voice/sub-components/Stopwatch";

const EndCallButton = ({
  buttonLabel,
  iconDefinition,
  onClick,
  stopWatch,
}: IEndCallButtonProps) => {
  const endCallButtonProps = {
    className: "end-call",
    id: "end-call-btn",
    onClick,
    "data-testid": "end-call-btn",
  };

  return (
    <>
      <Stopwatch {...stopWatch} />
      <button {...endCallButtonProps}>
        <FontAwesomeIcon {...iconDefinition} />
        <span className="btn-label">
          {buttonLabel}
        </span>
      </button>

      <style jsx>
        {`

          button {
            background-color: ${theme.brandColors.primaryGray};
            border: none;
            border-radius: 10rem;
            color: ${theme.fontColors.white};
            font-size: 3.4rem;
            font-weight: bold;
            height: 80%;
            text-align: center;
            text-transform: uppercase;
          }

          button.end-call {
            background-color: ${theme.brandColors.red};
            width: 60%;
          }

         .btn-label {
            margin-left: 4.5%;
            text-transform: uppercase;
            font-weight: bold;
          }
        `}
      </style>
      <style>
        {`
          button.end-call svg path {
            transform: rotate(135deg);
            transform-origin: 40% 50%;
          }
        `}
      </style>
    </>
  )
}

export default EndCallButton;
