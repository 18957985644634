// Icons
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";

// Node Modules
import {
  useEffect,
  useState,
} from "react";

// Themes
import theme from "@/data/theme";

// Types
import CenteredModal from "../../modals/CenteredModal";
import ICallErrorModalProps from "@/interfaces/global-components/video/sub-components/ICallErrorModalProps";

const CallErrorModal = ({
  countDown,
  modalBodyText,
  modalHeader,
  setCountDown,
  modalOnClose,
}: ICallErrorModalProps) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    const countDownIntervalId = setInterval(() => {
      countDown > 0 && setCountDown(counter => counter - 1);
    }, 1000);

    setIntervalId(countDownIntervalId);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <CenteredModal
        iconPulse
        icon={countDown > 0 && faSpinner}
        iconColor={theme.brandColors.primaryGray}
        iconSize="4x"
        isModalClosable={countDown <= 0}
        title={modalHeader}
        onClose={() => modalOnClose()}
      >
        {countDown <= 0
          ? (
            <div
              className="text-container"
              role="text-container"
            >
              <p>{modalBodyText}</p>
            </div>
            ) :
            (
              <p>
                Retrying in {countDown} seconds
              </p>
            )}
      </CenteredModal>
      <style jsx>
        {`
          p {
            padding-top: 10rem;
          }

          .text-container {
            padding-top: 5rem;
          }
        `}
      </style>
    </>
  )
}

export default CallErrorModal
