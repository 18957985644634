// Components
import Audio from "@/gc/audio/Audio";
import ConnectingCallButton from "@/components/global-components/video/sub-components/ConnectingCallButton";
import EndCallButton from "@/gc/video/sub-components/EndCallButton";
import StartCallButton from "@/gc/video/sub-components/StartCallButton";

// Constants
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";

// Context
import { useCustomerSupportContext } from "@/context/CustomerSupportContext";
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";
import { useTimerContext } from "@/context/TimerContext";
import { useVideoCallContext } from "@/context/VideoCallContext";

// Enums
import { CallError } from "@/enums/CallError";

// FontAwesome Icons
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";

// Helpers
import { detachLocalParticipantTracks } from "@/scripts/videoCallHelper";
import { formatRemoteProgram } from "@/pages/api/helpers/stringHelper";

// Node Modules
import dynamic from "next/dynamic";
import Video, {
  AudioTrackPublication,
  Participant,
  RemoteAudioTrack,
  Room,
  createLocalTracks,
} from "twilio-video";
import {
  useEffect,
  useState
} from "react";

// Scripts
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";

// Services
import dataLayerService from "@/services/dataLayerService";
import twilioService from "@/services/twilioService";
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Themes
import theme from "@/theme";

// Types
import CallErrorModal from "@/components/global-components/video/sub-components/CallErrorModal";
import { ICallErrorModalModified } from "@/src/models/contentful/ICallErrorModalModified";
import ICustomIconDefinitionProps from "@/interfaces/global-components/icons/ICustomIconDefinitionProps";
import IVideoHandlerProps from "@/interfaces/global-components/video/IVideoHandlerProps";

const IncomingCallButton = dynamic(() => import("./sub-components/IncomingCallButton"), {
  ssr: false,
});

const VideoHandler = ({
  content,
  disabled,
}: IVideoHandlerProps) => {
  const globalLayoutFields = content?.globalLayout?.fields;
  const outboundCallRingtone = content.globalLayout.fields.callRingtones.find(item => item.fields.key === "KIOSK_OUTBOUND_RINGTONE" && item.fields.enabled);
  const videoTaskTimeout = content.globalLayout.fields.twilioTaskTimeout;
  const [isRoomReady, setIsRoomReady] = useState<boolean>(false);
  const [isUserLeavingRoom, setIsUserLeavingRoom] = useState<boolean>(false);
  const [showCallErrorModal, setCallErrorModal] = useState<boolean>(false);
  const [callErrorModalValues, setCallErrorModalValues] = useState<ICallErrorModalModified>();
  const [countDown, setCountDown] = useState<number>();
  const [playAudio, setPlayAudio] = useState<boolean>(false);

  const {
    callState,
    hasAgentEnteredTheRoom,
    isCallDisconnecting,
    isIncomingCall,
    room,
    setCallState,
    setHasAgentEnteredTheRoom,
    setIsCallDisconnecting,
    setIsIncomingCall,
    setRoom,
    setTaskSid,
    stopWatch,
    taskSid,
  } = useVideoCallContext();

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const {
    startCallFunctionRef,
  } = useCustomerSupportContext();

  const {
    audioElement: audioRef,
  } = useVideoCallContext();

  const {
    disableTimer,
    enableTimer,
  } = useTimerContext();

  const addBasicEventsToRoom = (room: Room): void => {
    room.on("participantConnected", (participant: Participant) => {
      participant.on("trackSubscribed", (track: RemoteAudioTrack) => {
        document.getElementById("remote-media")?.appendChild(track.attach());
      });

      handleOnParticipantConnectedEvent(room.participants.size);
    });

    room.on("participantDisconnected", () => {
      if (noRemoteParticipantsAreConnected(room)) {
        detachLocalParticipantTracks(room);
        endCall();
      }
    });
  };

  useEffect(() => {
    if (room) {
      room.participants.forEach((participant: Participant) => {
        participant.audioTracks.forEach((track: AudioTrackPublication) => {
          document.getElementById("remote-media").appendChild(track.track.attach());
        });
      });

      addBasicEventsToRoom(room);
    }
    startCallFunctionRef.current = startOutboundCall;
  }, []);

  const leaveRoom = (room: Room): void => {
    room.disconnect();
    resetCallState();
    setRoom(null);
    stopWatch.reset(undefined, false);
  };

  useEffect(() => {
    if (isUserLeavingRoom) {
      stopWatch.reset(undefined, false);
      resetCallState();
    }

    if (isUserLeavingRoom && room) {
      const leaveRoom = (): void => {
        room.disconnect();
        setRoom(null);
      };

      leaveRoom();
    }

    if (isUserLeavingRoom && taskSid) {
      const deleteVideoTaskFromTwilio = async (): Promise<void> => {
        try {
          const shouldDeteleVideoTask: boolean =
            (!room || noRemoteParticipantsAreConnected(room)) && !hasAgentEnteredTheRoom;

          if (shouldDeteleVideoTask) {
            setTaskSid(null);
            await twilioService.deleteVideoTask(taskSid);
          }
        } catch (error) {
          logOnDataDog(error, StatusType.error);
        }
      };

      deleteVideoTaskFromTwilio();
    }

    if (isUserLeavingRoom && !taskSid && !room) {
      setIsUserLeavingRoom(false);
      setHasAgentEnteredTheRoom(false);
    }

    if (room && isCallDisconnecting && isIncomingCall) {
      endCall();
    }
  }, [
    isUserLeavingRoom,
    room,
    taskSid,
  ]);

  useEffect(() => {
    if (isUserLeavingRoom && isIncomingCall) {
      setIsIncomingCall(false);
    }
    if (isUserLeavingRoom) {
      setIsCallDisconnecting(false)
    }
  }, [isUserLeavingRoom]);

  const endCall = (): void => {
    setPlayAudio(false);
    setIsUserLeavingRoom(true);
    setIsRoomReady(false);

    dataLayerService.pushEvent({
      event: dataLayerTypes.events.kioskVideoCallEnded,
    });
  }

  const [iconDefinition, setIconDefinition] = useState<ICustomIconDefinitionProps>({
    color: theme.brandColors.white,
    icon: faPhoneAlt,
    size: "lg",
  });

  const handleOnParticipantConnectedEvent = (numberOfRemoteParticipants: number): void => {
    if (numberOfRemoteParticipants === 1) {
      setHasAgentEnteredTheRoom(true);
      setPlayAudio(false);
    }
    stopWatch.start();
    if (!isCallDisconnecting) {
      setCallState(callState => ({
        ...callState,
        buttonLabel: globalLayoutFields?.footerEndCallButtonText,
        callStatus: callStatuses.active,
      }));

      dataLayerService.pushEvent({
        event: dataLayerTypes.events.kioskVideoCallAnswered,
      });

      setIconDefinition(iconDefinition => ({
        ...iconDefinition,
        color: theme.brandColors.white,
      }))
    }
  };

  const noRemoteParticipantsAreConnected = (room: Room) => room?.participants?.size === 0;

  const handleErrorLogging = (error: Error, room: Room): void => {
    if (error) {
      logOnDataDog(error.message, StatusType.error, room);
    }
  };

  const setErrorFields = (errorCode: string): void => {
    const errorContent = globalLayoutFields?.callErrorModals?.find((callErrorModal: ICallErrorModalModified) => callErrorModal.fields?.errorCode === errorCode);

    setCountDown(errorContent?.fields?.countDownSeconds)
    setCallErrorModalValues(errorContent);
  };

  const modalOnClose = () => {
    setCallErrorModal(false);
    setPlayAudio(false)
    resetCallState();
    resetRoomState();
  };

  const addErrorEvents = (room: Room): void => {
    room.on("disconnected", (room: Room, error: Error) => {
      handleErrorLogging(error, room);
    });

    room.on("reconnecting", (error: Error) => {
      setCallErrorModal(true)
      setErrorFields(CallError.RECONNECTING);
      handleErrorLogging(error, room);
    });

    room.on("reconnected", () => {
      setCallErrorModal(false);
    });

    room.on("trackSubscriptionFailed", (error: Error) => {
      handleErrorLogging(error, room);
    });
  };

  const startOutboundCall = async (): Promise<void> => {
    setPlayAudio(true);
    await setUpRoom();
  };

  const setUpRoom = async (): Promise<void> => {
    resetRoomState();
    try {
      const {
        kioskId,
        ozStoreId,
        program,
        storeNumber,
      } = deviceInformation;

      if (storeNumber) {
        const kioskRoomName: string = `Site ${storeNumber}${formatRemoteProgram(program)}`;
        setDialingCallState();

        const tracks = await createLocalTracks({
          audio: true,
        });

        const connectOptions: Video.ConnectOptions = {
          tracks,
          automaticSubscription: true,
          name: kioskRoomName,
        };

        const videoToken = await twilioService.getVideoToken(kioskRoomName);
        const joinedRoom: Room = await Video.connect(videoToken, connectOptions);

        addErrorEvents(joinedRoom);

        if (!isIncomingCall) {
          const twilioVideoSessionId = joinedRoom.sid;
          const taskSid: string = await twilioService.createVideoTask(kioskRoomName, kioskRoomName, kioskId, ozStoreId, storeNumber, twilioVideoSessionId, undefined, videoTaskTimeout);
          setTaskSid(taskSid);
        } else {
          joinedRoom.participants.forEach((participant: Participant) => {
            participant.on("trackSubscribed", track => {
              if (track && track.kind !== "data") {
                document.getElementById("remote-media")?.appendChild(track.attach());
              }
            });
          });

          handleOnParticipantConnectedEvent(joinedRoom.participants.size);
        }

        addBasicEventsToRoom(joinedRoom);
        setRoom(joinedRoom);
        setIsRoomReady(true);
      }
    } catch (error) {
      setCallErrorModal(true)
      setErrorFields(CallError.OUTGOING_FAILED);
      logOnDataDog(error, StatusType.error);
    }
  };

  const setDialingCallState = () => {
    const dataLayerEventName = isIncomingCall
      ? dataLayerTypes.events.kioskInboundVideoCall
      : dataLayerTypes.events.kioskOutboundVideoCall

    dataLayerService.pushEvent({
      event: dataLayerEventName,
    })

    setCallState(callState => ({
      ...callState,
      buttonLabel: globalLayoutFields?.footerDialingButtonText,
      callStatus: callStatuses.connecting,
    }));

    setIconDefinition(iconDefinition => ({
      ...iconDefinition,
      color: theme.surfaceColors.black,
    }));
    disableTimer();
  };

  const resetDialingVolume = () => {
    if (audioRef.current) {
      audioRef.current.volume = 1;
    }
  };

  const resetCallState = () => {
    setCallState(callState => ({
      ...callState,
      buttonLabel: globalLayoutFields?.footerCallButtonText,
      callStatus: callStatuses.inactive,
    }));

    setIconDefinition(iconDefinition => ({
      ...iconDefinition,
      color: theme.surfaceColors.white,
    }));

    resetDialingVolume();
    enableTimer();
  };

  const resetRoomState = () => {
    setIsUserLeavingRoom(false);
    setHasAgentEnteredTheRoom(false);
    setTaskSid(null);
    setRoom(null);
  }

  useEffect(() => {
    if (countDown <= 0 && room) {
      leaveRoom(room);
    }
  }, [countDown]);

  return (
    <>
      {showCallErrorModal && (
        <CallErrorModal
          countDown={countDown}
          modalBodyText={callErrorModalValues?.fields.modalBodyText}
          modalHeader={callErrorModalValues?.fields.modalHeader}
          modalOnClose={modalOnClose}
          setCountDown={setCountDown}
        />
      )}
      <div
        className="videocall-handler-container"
        role="video-call-handler-container"
      >
        <div
          className="preview"
          id="remote-media"
          style={{
            display: "inline-block",
            alignItems: "center",
          }}
        />

        <Audio
          loop
          audioRef={audioRef}
          audioSrcUrl={outboundCallRingtone?.fields.media.fields.file.url}
          dataTestId="call-chime"
          playAudio={!!outboundCallRingtone && playAudio}
        />

        {!isIncomingCall && (callState.callStatus === callStatuses.inactive) && (
          <StartCallButton
            content={content}
            disabled={disabled}
            startCall={startOutboundCall}
          />
        )}

        <IncomingCallButton
          chimeOnEnd={setUpRoom}
          globalLayout={content.globalLayout}
          playChime={isIncomingCall && (callState.callStatus === callStatuses.inactive)}
        />

        {callState.callStatus === callStatuses.connecting && (
          <ConnectingCallButton
            callState={callState}
            globalLayout={content.globalLayout}
            iconDefinition={iconDefinition}
            isIncomingCall={isIncomingCall}
            isRoomReady={isRoomReady}
            onClick={(taskSid || isIncomingCall)
              ? endCall
              : undefined}
          />
        )}

        {callState.callStatus === callStatuses.active && (
          <EndCallButton
            buttonLabel={callState.buttonLabel}
            iconDefinition={iconDefinition}
            stopWatch={stopWatch}
            onClick={(taskSid || isIncomingCall)
              ? endCall
              : undefined}
          />
        )}
      </div>

      <style jsx>
        {`
          div.videocall-handler-container {
            align-items: center;
            background-color: ${theme.brandColors.white};
            display: flex;
            height: 100%;
            justify-content: end;
            width: 100%;
          }
        `}
      </style>
    </>
  );
};

export default VideoHandler;
