import {
  LocalTrackPublication,
  Room
} from "twilio-video";

const detachLocalParticipantTracks = (room: Room) => {
  room.localParticipant.tracks.forEach((publication: LocalTrackPublication) => {
    if (publication.track && publication.track.kind !== "data") {
      const attachedElements = publication.track.detach();
      attachedElements.forEach(element => element.remove());
    }
  });
};

export { detachLocalParticipantTracks };
