// Components
import BackButton from "@/gc/footer/sub-components/BackButton";
import CallHandler from "@/gc/voice/CallHandler";
import HomeButton from "@/gc/footer/sub-components/HomeButton";
import VideoHandler from "@/gc/video/VideoHandler";
import {
  useEffect,
  useState
} from "react";

// Context
import { useSchedulesContext } from "@/context/SchedulesContext";
import {
  featureNames,
  useFeatureToggleContext
} from "@/context/FeaturesToggleContext";

// enums
import { FlexEnabledFeature } from "@/enums/optimizely/FlexEnabledFeature";

// Node Modules
import { useDecision } from "@optimizely/react-sdk";

// Styles
import { getFooterStyles } from "./StylesFooter";

// Types
import IFeatureToggle from "@/interfaces/device/features/IFeatureToggle";
import IFooterProps from "@/interfaces/global-components/footer/IFooterProps";
import Tooltip from "@/gc/tool-tip/ToolTip";

// Utils
import { buildNSCHoursTooltipText } from "@/__mocks__/utils/hoursOfOperationUtils";

const Footer = ({
  content,
  disableBackButton = false,
  handleBack,
  homeButtonOnClick,
  isHomeButtonEnabled = true,
}: IFooterProps) => {
  const [isPhoneDisabled, setIsPhoneDisabled] = useState<boolean>(false);
  const [useTwilioFlexCallFunctionality, setUseTwilioFlexCallFunctionality] = useState<boolean>(false);

  const [decision] = useDecision(FlexEnabledFeature.Variation, {
    autoUpdate: true,
  });

  useEffect(() => {
    const {
      enabled,
    } = decision || {};

    setUseTwilioFlexCallFunctionality(enabled);

    const featureToggle: IFeatureToggle = {
      featureName: featureNames.flex,
      isEnabled: enabled,
    };

    setFeatureToggleList([...featureToggleList.filter(item => item.featureName != featureNames.flex), featureToggle]);
  }, [decision])

  const {
    className,
    styles,
  } = getFooterStyles();
  
  const {
    nscSchedule,
    isNscCurrentlyOpen,
  } = useSchedulesContext();
  
  const {
    featureToggleList,
    setFeatureToggleList,
  } = useFeatureToggleContext();

  useEffect(() => {
    setIsPhoneDisabled(!isNscCurrentlyOpen);
  }, [isNscCurrentlyOpen]);

  useEffect(() => {
    setIsPhoneDisabled(!isNscCurrentlyOpen);
  }, [isNscCurrentlyOpen]);

  const buildTooltipText = (): string => {
    return nscSchedule
      ? buildNSCHoursTooltipText(nscSchedule)
      : "";
  }

  return (
    <>
      <footer
        className={className}
        data-testid="footer"
      >
        <HomeButton
          homeButtonOnClick={homeButtonOnClick}
          isHomeButtonEnabled={isHomeButtonEnabled}
        />
        <BackButton
          content={content}
          disableBackButton={disableBackButton}
          handleBack={handleBack}
        />

        <div className={`${className} call-button-container`}>
          <Tooltip
            isChildrenBlockTag
            disable={!isPhoneDisabled}
            placement="top"
            toolTipContent={buildTooltipText()}
          >
            <div className={`${className} tooltip-children-container`}>
              {
                useTwilioFlexCallFunctionality
                  ? (
                    <VideoHandler
                      content={content}
                      disabled={isPhoneDisabled}
                    />
                    )
                  : (
                    <CallHandler
                      content={content}
                      disabled={isPhoneDisabled}
                    />
                    )
              }
            </div>
          </Tooltip>
        </div>
      </footer>
      {styles}
    </>
  );
};

export default Footer;
