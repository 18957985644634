// Node modules
import {
  useEffect,
  useRef,
} from "react";

// Types
import IAudioProps from "@/interfaces/global-components/audio/IAudioProps";

const Audio = ({
  audioRef: audioElement,
  audioSrcUrl,
  dataTestId = "audio",
  loop,
  playAudio,
  onEnded,
}: IAudioProps) => {
  const audioRef = audioElement ?? useRef<HTMLAudioElement>(null);
  
  const startAudio = (): void => {
    audioRef.current.play()
  };
  const stopAudio = (): void => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };

  useEffect(() => {
    if (playAudio) {
      startAudio();
    } else {
      stopAudio();
    }
  }, [playAudio]);

  return (
    <audio
      data-testid={dataTestId}
      loop={loop}
      ref={audioRef}
      role="audio"
      onEnded={onEnded}
    >
      <source
        role="source"
        src={audioSrcUrl}
      />
    </audio>
  )
}

export default Audio;
